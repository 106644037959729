import { WhyChooseUsStepOptions } from "@/types";
import React, { FC } from "react";
import StarIconTitle from "../../StarIconSectionTitleDefine";

const WhyChooseUsWithSteps: FC<{ props: WhyChooseUsStepOptions }> = ({
  props: { backgroundImage, description, heading, sectionTitle, whyChooseUs },
}) => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <StarIconTitle title={sectionTitle} />
              <h2>{heading}</h2>
              <p>{description.description}</p>
              <div className="inner-box">
                {whyChooseUs?.map(({ id, title, description }, i) => (
                  <div className="single-item" key={id}>
                    <div className="count-box">{i + 1}</div>
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={backgroundImage.file.url} alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsWithSteps;
