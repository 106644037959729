import { graphql, useStaticQuery } from "gatsby";
import React from "react";

export default function CircleShapeBackground(): JSX.Element {
  const {
    contentfulAdditionalAssets: {
      asset: {
        file: { url: shape1 },
      },
    },
  } = useStaticQuery(graphql`
    query {
      contentfulAdditionalAssets(title: { eq: "Circle Shape 1" }) {
        asset {
          file {
            url
          }
        }
      }
    }
  `);

  return (
    <div className="circle-shape1">
      <img src={shape1} alt="banner" />
    </div>
  );
}
