import React from "react";

export default function StarIconSectionTitleDefine({ title }): JSX.Element {
  if (!title) return null;

  return (
    <span className="sub-title">
      <img src={"/images/star-icon.png"} alt="banner" />
      {title}
    </span>
  );
}
