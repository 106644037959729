import {
  BannerWithStatsCardOptions,
  SEOType,
  TechnologiesWeUseOptions,
  WhyChooseUsStepOptions,
} from "@/types";
import React, { FC } from "react";
import Layout from "../App/Layout";
import SEO from "../SEO";
import { BannerWithStatsCard } from "../Shared/Design/BannerWithStatsCards";
import TechnologiesWeUse from "../Shared/Design/HowItWork";
import WhyChooseUsWithSteps from "../Shared/Design/WhyChooseUsWithSteps";
import BreadCrumb from "../Shared/extra/BreadCrumb";

interface AboutUsProps {
  props: {
    seo: SEOType;
    banner: BannerWithStatsCardOptions;
    whyChooseUs: WhyChooseUsStepOptions;
    technologiesWeUse: TechnologiesWeUseOptions;
    footerContent?: any;
  };
}

const AboutUs: FC<AboutUsProps> = ({
  props: { seo, banner, whyChooseUs, technologiesWeUse, footerContent },
}) => {
  return (
    <Layout footerContent={footerContent}>
      <SEO contentfulSeo={seo} />
      <BreadCrumb
        pageTitle="About Us"
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/about/", pathName: "About us" },
        ]}
      />
      <BannerWithStatsCard props={banner} />
      <WhyChooseUsWithSteps props={whyChooseUs} />
      <TechnologiesWeUse props={technologiesWeUse} />
    </Layout>
  );
};

export default AboutUs;
