import { TechnologiesWeUseOptions } from "@/types";
import React, { FC } from "react";
import CircleShapeBackground from "../../../helpers/CircleShapeBackground";
import StarIconTitle from "../../StarIconSectionTitleDefine";

const TechnologiesWeUse: FC<{ props: TechnologiesWeUseOptions }> = ({
  props: { description, heading, sectionTitle, technologyStack },
}) => {
  return (
    <section className="process-area pb-70">
      <div className="container">
        <div className="section-title">
          <StarIconTitle title={sectionTitle} />
          <h2>{heading}</h2>
          <p>{description.description}</p>
        </div>

        <div className="row justify-content-center">
          {technologyStack?.map(
            (
              {
                id,
                icon: {
                  file: { url },
                },
              },
              i,
            ) => (
              <div className="col-lg-3 col-md-6" key={id}>
                <div className="single-process-box" style={{ padding: "10px" }}>
                  <div className="image mb-0">
                    <img src={url} alt="about" style={{ height: "85px" }} />
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>

      <CircleShapeBackground />
    </section>
  );
};

export default TechnologiesWeUse;
