import { BannerWithStatsCardOptions } from "@/types";
import { Link } from "gatsby";
import React, { FC } from "react";
import CircleShapeBackground from "../../../helpers/CircleShapeBackground";

export const BannerWithStatsCard: FC<{ props: BannerWithStatsCardOptions }> = ({
  props: { bannerBackgroundImage, description, featuresList, heading },
}) => {
  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={bannerBackgroundImage.file.url} alt="banner-bgi" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                {/* Star Icon Ref */}
                <h2>{heading} </h2>
                <p>{description.description}</p>

                <ul className="features-list">
                  {featuresList?.map(
                    ({
                      id,
                      heading,
                      subHeading,
                      icon: {
                        file: { url },
                      },
                    }) => (
                      <li key={id}>
                        <img src={url} alt="banner" />
                        <h3>{heading}</h3>
                        <p>{subHeading}</p>
                      </li>
                    ),
                  )}
                </ul>

                <Link to="/contact/" className="default-btn">
                  <i className="flaticon-right"></i>Get Free Quote <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CircleShapeBackground />
    </section>
  );
};
