import AboutUs from "@/components/AboutUs/index";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function About(): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        query GET_ABOUTUS_PAGE {
          contentfulAboutUsPage {
            # SEO Tags
            seo {
              metaTitle
              metaDescription
              metaUrl
              metaAuthor
              metaKeywords
              metaImage {
                file {
                  url
                }
              }
            }

            # Banner With Stats Card
            banner {
              heading
              bannerBackgroundImage {
                file {
                  url
                }
              }
              description {
                description
              }
              featuresList {
                id
                heading
                subHeading
                icon {
                  file {
                    url
                  }
                }
              }
            }

            # Why choose Us
            whyChooseUs {
              heading
              sectionTitle
              description {
                description
              }
              backgroundImage {
                file {
                  url
                }
              }
              whyChooseUs {
                id
                title
                description
              }
            }

            #Tech Stack
            technologiesWeUse {
              sectionTitle
              heading
              description {
                description
              }
              technologyStack {
                id
                title
                icon {
                  file {
                    url
                  }
                }
              }
            }

            # Footer Content
            footerContent {
              raw
            }
          }
        }
      `}
      render={({
        contentfulAboutUsPage: { seo, banner, whyChooseUs, technologiesWeUse, footerContent },
      }) => <AboutUs props={{ seo, banner, whyChooseUs, technologiesWeUse, footerContent }} />}
    />
  );
}
